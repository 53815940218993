// @ts-nocheck
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';

import { Component, ErrorInfo } from 'react';
import TiktokPixel from 'tiktok-pixel';

import client from './apollo/client';
import Routes from './routes';
import GlobalStyle from './styles/GlobalStyle';
import LoginChecker from './components/Auth/LoginChecker';
import { UserProvider } from './context/user';
import refreshClientCache from './utils/refreshClientCache';

import TheHeader from './components/TheHeader';

const sentry = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  dsn: sentry || '',
});

const globalGrid = {
  breakpoints: {
    lg: 1025,
    xl: 1367,
  },
  container: {
    maxWidth: {
      xl: 1440,
    },
  },
};

const tiktokPixelOptions = {
  debug: false,
};

class JorCustom extends Component {
  componentDidMount() {
    refreshClientCache();
    TiktokPixel.init('CK3HQL3C77U25LTFRF5G', {}, tiktokPixelOptions);
  }

  componentDidCatch(
    error: Error,
    errorInfo: ErrorInfo & Record<string, string>,
  ) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <GlobalStyle />

        <BaseCSS />

        <GridThemeProvider gridTheme={globalGrid}>
          <BrowserRouter>
            <UserProvider>
              <TheHeader />

              <LoginChecker>
                <Routes />
              </LoginChecker>
            </UserProvider>
          </BrowserRouter>
        </GridThemeProvider>
      </ApolloProvider>
    );
  }
}

export default JorCustom;
