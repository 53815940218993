import React from 'react';

import ReactPixel from 'react-facebook-pixel';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';

import Helmet from 'react-helmet';

import ProductInfo from './Info';
import ProductMedia from './Info/ProductMedia';
import AlsoLikeSection from './Info/AlsoLikeSection';
import StyleWithSection from './Info/StyleWithSection';

import { useUserProvider } from '../../context/user';

interface Props {
  product: any;
}

export default function Product({ product }: Props) {
  const isMobile = useMediaQuery('(max-width:767px)');

  const facebookId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

  const {
    state: { user, currency },
  } = useUserProvider();

  const { imageGroups, styleWithProd, upsaleProducts } = product || {};

  const [isMounted, setMounted] = React.useState<boolean>(false);

  const analyticEventHandler = (properties: any) => {
    let itemDesign: string = '';

    if (properties?.designs?.length > 0) {
      const excludeExtension = properties?.designs[0].name
        .split('.')[0]
        .split('_');

      excludeExtension.pop();

      itemDesign = properties?.name?.split(' ')[0];
    }

    // @ts-ignore
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_name: properties?.name || '',
            item_id: properties?.productSku || '',
            price: properties?.price?.amount || '',
            item_category: properties?.category?.name || '',
            item_category2: properties?.category?.parent?.name || '',
            item_category3: properties?.color?.name || '',
            item_category4: itemDesign || '',
            item_category5: 'Original',
            item_variant: '',
            quantity: 1,
          },
        ],
      },
    });

    // @ts-ignore
    window.snaptr('track', 'VIEW_CONTENT', {
      price: properties?.price?.amount || '',
      currency: currency || '',
      item_ids: [properties?.id || ''],
      item_category: properties?.category?.name || '',
      uuid_c1: '',
      user_email: user?.email || '',
      user_phone_number: user?.phone || '',
      user_hashed_email: '',
      user_hashed_phone_number: '',
    });
  };

  React.useEffect(() => {
    if (isMounted) {
      if (facebookId) ReactPixel.init(facebookId);
      if (product) analyticEventHandler(product);
    } else {
      setMounted(true);
    }
  }, [isMounted]);

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <title>JorCustom - {product.name}</title>
        <meta
          name="description"
          content={product.description.replace(/(<([^>]+)>)/gi, '')}
        />
      </Helmet>

      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Box sx={{ width: isMobile ? '100%' : '50%' }}>
          <ProductMedia imageGroups={imageGroups} />
        </Box>

        <Box
          sx={{
            position: isMobile ? 'static' : 'sticky',
            top: isMobile ? 0 : '8vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: isMobile ? '10px' : 0,
            width: isMobile ? '100%' : '50%',
            height: isMobile ? 'auto' : 'calc(100vh - 8vh)',
          }}
        >
          <ProductInfo product={product} />
        </Box>
      </Stack>

      <StyleWithSection products={styleWithProd} />

      <AlsoLikeSection products={upsaleProducts} />
    </Box>
  );
}
